<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import EmployerDashboardService from '@/core/employer-dashboard/employer-dashboard.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import MeService from '@/core/shared/me/me.service';

import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';

const employerDashboardService = new EmployerDashboardService();
const meService = new MeService();

const selectedDashboardId = ref('');
const embedJWT = ref('');
const isLoading = ref(true);

const employerDashboards = computed(() => employerDashboardService.employerDashboards);
const shouldDisplayDashboard = computed(() => embedJWT.value !== '');
const selectedEmployerId = computed(() => Number(meService.employer?.id));

async function initEmployerDashboard() {
  try {
    await employerDashboardService.getEmployerDashboardEmployer(selectedEmployerId.value);
    const employerDashboards = await employerDashboardService.getAllEmployerDashboards();
    // Select the first dashboard by default
    if (employerDashboards.length > 0) {
      selectedDashboardId.value = employerDashboards[0].id;
    }

    // Get the JWT token for the dashboard
    embedJWT.value = await employerDashboardService.generateEmbedJWT(
      selectedDashboardId.value,
      selectedEmployerId.value,
    );
  } catch (error) {
    SnackbarService.showSnackbar({
      variant: 'caution-alt',
      text: 'Error loading dashboards, please refresh',
      appendIcon: 'close',
      horizontalPosition: 'left',
    });
  }
}

async function selectDashboard(id: string) {
  isLoading.value = true;
  selectedDashboardId.value = id;
  embedJWT.value = '';

  try {
    // Get the JWT token for the dashboard
    embedJWT.value = await employerDashboardService.generateEmbedJWT(id, selectedEmployerId.value);
  } catch (error) {
    SnackbarService.showSnackbar({
      variant: 'caution-alt',
      text: 'Error loading dashboards, please refresh',
      appendIcon: 'close',
      horizontalPosition: 'left',
    });
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  try {
    isLoading.value = true;

    // Don't await so that the explo bundle can load in parallel
    initEmployerDashboard();

    const script = document.createElement('script');
    script.src = 'https://embed.explo.co/bundle.js';
    document.body.appendChild(script);
  } catch (error) {
    SnackbarService.showSnackbar({
      variant: 'caution-alt',
      text: 'Error loading dashboards, please refresh',
      appendIcon: 'close',
      horizontalPosition: 'left',
    });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <div class="min-h-dvh bg-highlight-0 pt-[21px]">
    <p class="mb-4 ml-8 font-serif text-[18px] font-black leading-6 text-black">
      Performance Dashboard
    </p>

    <!-- Dashboard List -->
    <div
      class="mx-8 flex h-[30px] items-center justify-start gap-4 border-b-[0.5px] border-b-[rgba(0,0,0,0.1)]"
    >
      <div
        v-for="dashboard in employerDashboards"
        :key="dashboard.id"
        class="flex h-[30px] cursor-pointer items-center justify-center text-[14px]"
        :class="{
          'border-b-2 border-b-highlight-500 font-bold text-highlight-500':
            selectedDashboardId === dashboard.id,
          'text-shade-800': selectedDashboardId !== dashboard.id,
        }"
        @click="selectDashboard(dashboard.id)"
      >
        {{ dashboard.name }}
      </div>
    </div>

    <!-- Dashboard -->
    <div v-if="shouldDisplayDashboard">
      <explo-dashboard :dash-jwt="embedJWT" updateUrlParams="{true}" isProduction="{true}" />
    </div>

    <!-- Loading -->
    <div v-if="isLoading" class="flex items-center justify-center">
      <SpinnerLoader class="mt-[350px] h-[68px] w-[68px]"></SpinnerLoader>
    </div>
  </div>
</template>
